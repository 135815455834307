@charset "utf-8";

@import 'mixin';
@import 'reset';
@import 'variable';

#header {
    &.fixed {
        position: relative;
    }
}

.cont {
    padding: 0 3%;
}

#mainvisual {
    margin-top: 0;
}


.circle-wrapper {
    position: absolute !important;
    top: 424px;
    right: 32px;
}